import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { getLanguage, getLocalizeText, getLocalizeUrl, getRedirectFromLineRate } from "../../misc/localization";
import {ReactComponent as ArrowDropDownIcon} from '../../assets/icons/arrowUp.svg';
import { Menu, MenuItem } from "@mui/material";
import LangSwitcher2 from "../../components/LandSwitcher2";

const TopMenu = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [anchorServices, setAnchorServices] = useState(null);
    const isServicesOpen = Boolean(anchorServices);
    const servicesClick = (event) => {
        setAnchorServices(event.currentTarget);
    };
    const servicesClose = () => {
        setAnchorServices(null);
    };

    const isActive = () => window.location.hostname.indexOf("top.") === 0;

    const goTo = (path) => {
        servicesClose();
        navigate(getLocalizeUrl(location, path));
    }

    const isServicesPage = () => {
        const pages = ["/search", "/carrier", "/analytics"];
        return pages.some(x => location.pathname.toLowerCase().indexOf(x) !== -1);
    }

    return <div className={`TopMenu`}>
        {/* @TODO: скрытие в рамках GPNSLGSTC-793 */}
        {/* <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/seafreight")}>{getLocalizeText("menues/top", "seafreight", getLanguage(location))}</NavLink> */}
        <a className={`menuItem ${isActive() ? "active" : ""}`} href={getRedirectFromLineRate(location, "top.")}>{getLocalizeText("menues/top", "rate", getLanguage(location))}</a>

        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/analytics")}>{getLocalizeText("menues/top", "analytics", getLanguage(location))}</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/about")}>{getLocalizeText("menues/top", "about", getLanguage(location))}</NavLink>

        {/* @TODO: скрытие в рамках GPNSLGSTC-793 */}
        {/* <div className={`menuItem ${isServicesPage() ? "active" : ""}`} onClick={servicesClick}>{getLocalizeText("menues/top", "services", getLanguage(location))} <ArrowDropDownIcon className={`menuIcon ${isServicesOpen ? "open" : ""}`} /></div>
        <Menu
            className="TopMenu_menu"
            anchorEl={anchorServices}
            open={isServicesOpen}
            onClose={servicesClose}
            autoFocus={false}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
            <MenuItem onClick={() => goTo("/search")}>{getLocalizeText("menues/top", "search", getLanguage(location))}</MenuItem>
            <MenuItem onClick={() => goTo("/carrier")}>{getLocalizeText("menues/top", "carrier", getLanguage(location))}</MenuItem>
            <MenuItem onClick={() => goTo("/analytics")}>{getLocalizeText("menues/top", "analytics", getLanguage(location))}</MenuItem>
        </Menu> */}

        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/contact")}>{getLocalizeText("menues/top", "contacts", getLanguage(location))}</NavLink>

        <div className="langContainer">
            <LangSwitcher2 />
        </div>
    </div>
}

export default TopMenu;
