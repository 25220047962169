import "./style.scss";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../misc/localization";
import { ReactComponent as IconBadge } from "../../assets/icons/badge-check.svg";
import { ReactComponent as IconLightning } from "../../assets/icons/lightning-bolt.svg";


const TariffCardItem = ({ tariff, onClick, fullBadge }) => {
    const location = useLocation();
    const getCurrency = (country) => {
        switch (country) {
            case "tr":
                return "₺";
            case "en":
                return "$";
            default:
                return "₽";
        }
    };

    const getPrice = (country, price) =>  {
        if (!price) {
            return "";
        } else {
            return Number(price).toLocaleString('ru-RU') + " " + getCurrency(country);
        }
    };

    return (
        <div
            className={`tariffCard ${tariff.optimal ? 'optimal' : ''}`}
        >
            {
                tariff.optimal ?
                    <div className="tariffCardOptimalBadge">
                        <IconLightning />
                        {getLocalizeText("pages/analytics", "tariffs.optimal", getLanguage(location))}
                    </div>
                        : null
            }
            <div className="tariffCardTitleWrapper">
                <h4 className="tariffCardTitle">{tariff.name}</h4>

                {
                    tariff.optimal ?
                        <div className="tariffCardOptimalBadge mobile">
                            <IconLightning />
                            {getLocalizeText("pages/analytics", fullBadge ? "tariffs.optimal" : "tariffs.optimalShort", getLanguage(location))}
                        </div>
                        : null
                }
            </div>
            <p className="tariffCardOldPrice">{getPrice(tariff.country, tariff.prevPrice)}</p>
            <p className="tariffCardPrice">{getPrice(tariff.country, tariff.price)}<small>/ {getLocalizeText("pages/analytics", "tariffs.duration", getLanguage(location))}</small></p>
            <p className="tariffCardInfo">{getLocalizeText("pages/analytics", "tariffs.vatInfo", getLanguage(location))}</p>
            <ul className="tariffCardOptions">
                {
                    tariff.optionsOn?.map((option, idx) => (
                        <li key={`optionsOn-${idx}`} className="tariffCardOptionsItem optionsOn">
                            <IconBadge/>
                            {option}
                        </li>
                    )) || []
                }
                {
                    tariff.optionsOff?.map((option, idx) => (
                        <li key={`optionsOff-${idx}`} className="tariffCardOptionsItem optionsOff">
                            <IconBadge/>
                            {option}
                        </li>
                    )) || []
                }
            </ul>
            <Button
                className={`btn ${tariff.optimal ? '' : 'outlined reset'}`}
                variant="outlined"
                onClick={() => onClick()}
            >
                {getLocalizeText("pages/analytics", "tariffs.select", getLanguage(location))}
            </Button>
        </div>
    )
}
const TariffCard = ({ tariffs, chooseTariff, fullBadge }) => {

    return (
        <div className="tariffCardWrapper">
            {
                tariffs.map((tariff) => (
                    <TariffCardItem
                        key={tariff._id}
                        tariff={tariff}
                        fullBadge={fullBadge}
                        onClick={() => chooseTariff(tariff._id, tariff.name ,tariff.period)}
                    />
                ))
            }
        </div>
    )
}

export default TariffCard;
