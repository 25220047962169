import React, { memo } from "react";
import { getHost } from "../../misc/api";

const MailFooterTr = memo(() => {
    return (
        <div style={{ backgroundColor: "#E9F3FD", padding: "36px 60px" }}>
          <div style={{ display: "inline-block", width: "360px" }}>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://top.sealogic.io/tr/?utm_source=mail&utm_medium=mail&utm_campaign=line_rate"
            >
              Hatların derecelendirmesi
            </a>
            <a
              style={{
                  display: "block",
                  height: "24px",
                  marginBottom: "10px",
                  fontFamily: "Arial",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#334155",
                  textDecoration: "none !important",
              }}
              href="https://sealogic.io/tr/analytics?utm_source=mail&utm_medium=mail&utm_campaign=analytics"
            >
              Teklif analizi
            </a>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/tr/about?utm_source=mail&utm_medium=mail&utm_campaign=company"
            >
              Şirket hakkında
            </a>
            {/* @TODO: скрытие в рамках GPNSLGSTC-793 */}
            {/* <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/tr/carrier?utm_source=mail&utm_medium=mail&utm_campaign=carrier"
            >
              Kargo taşıyıcıları için
            </a> */}
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/tr/contact?utm_source=mail&utm_medium=mail&utm_campaign=contact"
            >
              Rehber
            </a>
          </div>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#0F172A",
                fontWeight: "600",
              }}
            >
              Bize ulaşın
            </div>
            <div style={{ marginTop: "16px" }}>
              <a
                href="https://t.me/sealogic?utm_source=telegram&utm_medium=email&utm_campaign=chanel"
                style={{ marginRight: "12px" }}
              >
                <img
                  style={{
                    textDecoration: "none !important",
                    width: "36px",
                    height: "36px",
                  }}
                  src={`${getHost()}/images/tg.png`}
                  alt="telegram"
                />
              </a>
              <a href="mailto:info@sealogic.io">
                <img
                  style={{
                    textDecoration: "none !important",
                    width: "36px",
                    height: "36px",
                  }}
                  src={`${getHost()}/images/gmail.png`}
                  alt="mail"
                />
              </a>
            </div>
          </div>
        </div>
    );
});

export default MailFooterTr;
